import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import Slider from "@material-ui/core/Slider";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { getPolicy, getToken, apiurl, getClient } from "./utils";
import axios from "axios";
import UpLoadProgressBar from "./UpLoadProgressBar";
import Switch from '@material-ui/core/Switch';
import { Row } from "gestalt";

// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#000",
    width: "100%",
  },
}));
let x = 0;

const AssetTabs = (props) => {
  const { assetId, rounds, assetStatus, approvedR, clientName } = props;
  const userPolicy = getPolicy();
  const token = getToken();
  const [showLoader, setShowLoader] = useState(false);
  const [uploadPrecentage, setuploadPrecentage] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(assetStatus);
  const [approvedRound, setApprovedRound] = useState(approvedR);
  const [assetRounds, setAssetRounds] = useState(rounds);
  const [zoom, setZoom] = useState("auto");

  useEffect(() => {
    x = x + 1;
    if (x < 3) {
      setAssetRounds(rounds);
    }
  });
  const valuetext = (value) => {
    setZoom(value);
    return `${value}px`;
  };

  // handle Deleting and Closing the current Round
  const [open, setOpen] = useState(false);

  const handleApprove = async (approvedRoundId) => {
    const data = {
      status: "approved",
      approvedround: approvedRoundId,
    };
    try {
      await axios
        .put(`${apiurl}/assets/${assetId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          axios
            .get(`${apiurl}/assets/${assetId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resposne) => {
              setApprovalStatus(resposne.data.status);
              setApprovedRound(resposne.data.approvedround);
            });
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancelApprove = async (approvedRoundId) => {
    const data = {
      status: "inreview",
      approvedround: "",
    };
    try {
      await axios
        .put(`${apiurl}/assets/${assetId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          axios
            .get(`${apiurl}/assets/${assetId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resposne) => {
              setApprovalStatus(resposne.data.status);
              setApprovedRound(resposne.data.approvedround);
            });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (e) => {
    try {
      await axios
        .delete(`${apiurl}/upload/files/${e}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          axios
            .get(`${apiurl}/assets/${assetId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resposne) => {
              setAssetRounds(resposne.data.rounds);
            });
        });
    } catch (error) {
      console.error(error);
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  //Add new rounds
  const handleLogoChange = async (event, expandedAsset) => {
    event.preventDefault();
    setShowLoader(true);

    const allFiles = event.target.files;
    const roundData = new FormData();
    for (let i = 0; i < allFiles.length; i++) {
      roundData.append("files", allFiles[i]);
    }
    roundData.append("ref", "assets");
    roundData.append("refId", expandedAsset);
    roundData.append("field", "rounds");

    await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data: roundData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setuploadPrecentage(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    }).then((response) => {
      const newRound = {
        id: response.data[0].id,
        name: response.data[0].name,
        width: response.data[0].width,
        height: response.data[0].height,
        mime: response.data[0].mime,
        alternativeText: response.data[0].alternativeText,
        url: response.data[0].url,
      };
      setAssetRounds([...assetRounds, newRound]);
      setShowLoader(false);
    });
  };

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reversedRounds = assetRounds.slice(0).reverse();

  const [switchState, setSwitchState] = useState({
    dark: true,
    light: true,
  });

  const handleSwitchChange = (event) => {
    console.log("Event: ", event.target.checked);
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
  };


  return (
    <Box className={classes.root}>
      <Box style={{ overflowX: "scroll" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            style={{
              backgroundColor: "#222",
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Rounds"
          >
            {reversedRounds.map((round, index, title) => {
              let roundTitle = title.length - index;
              return (
                <Tab
                  style={{
                    color: approvedRound === round.id ? "#33cc33" : "#fff",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  label={
                    getClient() === "Hulu" ||
                      clientName === "Hulu" ||
                      clientName === "Amazon"
                      ? "Comp - " + roundTitle
                      : "Round - " + roundTitle
                  }
                  {...a11yProps(index)}
                  key={index}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {reversedRounds.map((round, index, title) => {
          let roundTitle = title.length - index;
          return (
            <TabPanel value={value} index={index} key={index}>
              {showLoader === true ? (
                <Box style={{ width: "350px" }}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    style={{
                      width: "350px",
                      height: "190px",
                      paddingBottom: "15px",
                      paddingTop: "10px",
                    }}
                  />
                  <UpLoadProgressBar uploadPrecentage={uploadPrecentage} />
                </Box>
              ) : (
                  <Box style={switchState.dark == true ? { backgroundColor: '#000', paddingLeft: '10px' } : { backgroundColor: '#fff', paddingLeft: '10px' }}>
                    <Box>
                      <Box pt={2} display="flex" flexDirection="row" height={50}>
                        <Typography id="continuous-slider" gutterBottom style={switchState.dark == true ? { color: '#fff', paddingTop: '5px' } : { color: '#000', paddingTop: '5px' }}>
                          {switchState.dark == true ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                      </Typography>
                      <Switch
                        checked={switchState.dark}
                        onChange={handleSwitchChange}
                        name="dark"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                          style={{ color: '#ff5100' }}
                      />
                      </Box>
                      <Box pt={2} display="flex" flexDirection="row">
                        <Typography id="continuous-slider" gutterBottom style={switchState.dark == true ? { color: '#fff', paddingRight: '5px' } : { color: '#000', paddingRight: '5px' }}>
                          Zoom
                        </Typography>
                        <Box display="flex" flexDirection="row" width="300px">
                          <Typography style={switchState.dark == true ? { color: '#fff', paddingRight: "15px" } : { color: '#000', paddingRight: "15px" }}>
                            25%
                          </Typography>
                          <Slider
                            defaultValue={Math.round(round.width)}
                            getAriaValueText={valuetext}
                            aria-labelledby="Zoom"
                            valueLabelDisplay="auto"
                            step={1}
                            min={round.width / 4}
                            max={round.width}
                            style={{ color: '#ff5100' }}
                          />
                          <Typography style={switchState.dark == true ? { color: '#fff', paddingRight: "15px" } : { color: '#000', paddingRight: "15px" }}>
                            100%
                          </Typography>
                        </Box>
                    </Box>
                  </Box>
                  <CardMedia
                    style={{
                      width: zoom,
                      paddingBottom: "15px",
                      paddingTop: "10px",
                        paddingLeft: "15px",
                    }}
                    component="img"
                    alt={round.alternativeText}
                    width={round.width}
                    image={`${round.url}`}
                    title={round.name}
                  />

                  {approvedRound === round.id ? (
                    <Box style={{ font: "14px", color: "#33cc33" }}>
                      Approved
                    </Box>
                  ) : null}
                </Box>
              )}

              {userPolicy === "trailerparkuser" ? (
                <Fragment>
                  {roundTitle === 1 ? (
                    <Box height="36px" />
                  ) : (
                    <Button onClick={handleClickOpen}>
                      <DeleteForeverTwoToneIcon color="primary" />{" "}
                      {clientName === "Hulu" || clientName === "Amazon"
                        ? "Delete COMP"
                        : "Delete Round"}
                    </Button>
                  )}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Box
                      style={{
                        backgroundColor: "#222",
                        border: "solid 1px #ffffff",
                        radius: "10px",
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete"} "{round.name}"
                        {" file"}
                        {" ?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={(e) => handleDelete(round.id)}
                          color="primary"
                        >
                          Yes
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Cancel
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </Fragment>
              ) : null}
              <Box pb={2} pt={2}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="logo-button"
                  type="file"
                  multiple
                  onChange={(e) => handleLogoChange(e, assetId)}
                />
                {userPolicy === "trailerparkuser" ? (
                  <Fragment>
                    <label htmlFor="logo-button">
                      <Button
                        variant="outlined"
                        color="primary"
                        component="span"
                      >
                        {clientName === "Hulu" || clientName === "Amazon"
                          ? "Add COMPS"
                          : "Add Rounds"}
                      </Button>
                    </label>

                    <Box style={{ padding: "10px", fontSize: "16px" }}>
                      <span style={{ color: "#FF0000" }}>*</span> Make sure all
                      tabs are collapsed before you add new round
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    {approvalStatus !== "approved" ? (
                      <Fragment>
                        <Button
                          onClick={() => handleApprove(round.id)}
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          {getClient() === "Hulu" || clientName === "Amazon"
                            ? "Approve This COMP"
                            : "Approve This Round"}
                        </Button>
                        <Typography
                          style={{ padding: "10px", fontSize: "12px" }}
                        >
                          <span style={{ color: "#FF0000" }}>*</span> Please
                          make sure that the round you want to approve is
                          selected before you click on approve round.
                        </Typography>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Button
                          onClick={() => handleCancelApprove(round.id)}
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          Cancel Approval
                        </Button>
                        <Typography
                          style={{ padding: "10px", fontSize: "12px" }}
                        >
                          <span style={{ color: "#FF0000" }}>*</span> Please
                          make sure that the round you want to approve is
                          selected before you click on approve round.
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export default AssetTabs;
