import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { getClient, getPolicy } from "./utils";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
}));

const TheBreadcrumb = (props) => {
  const classes = useStyles();
  const { clientName, clientId, partnerName, projectName, partnerId } = props;

  if (partnerName === "partners") {
    return (
      <div className={classes.root}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {getClient() === "Peacock" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"Peacock"}
            </Link>
          ) : getClient() === "HBOMax" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"HBOMax "}
            </Link>
          ) : (
            <Button
              color="primary"
              variant="text"
              href={`/partners/${clientId}`}
            >
              {clientName}
            </Button>
          )}
          <Typography color="textPrimary">
            {getClient() === "Peacock" || clientName === "Peacock"
              ? "Projects"
              : "Partners"}
          </Typography>
        </Breadcrumbs>
      </div>
    );
  } else if (projectName === "projects") {
    return (
      <div className={classes.root}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {getClient() === "Peacock" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"Peacock"}
            </Link>
          ) : getClient() === "HBOMax" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"HBOMax "}
            </Link>
          ) : (
            <Button
              color="primary"
              variant="text"
              href={`/partners/${clientId}`}
            >
              {clientName}
            </Button>
          )}
          <Typography color="inherit">{partnerName}</Typography>
          <Typography color="textPrimary">
            {getClient() === "Peacock" || clientName === "Peacock"
              ? "Partners"
              : "Projects"}
          </Typography>
        </Breadcrumbs>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          {getClient() === "Peacock" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"Peacock"}
            </Link>
          ) : getClient() === "HBOMax" && getPolicy() === "hbomaxuser" ? (
            <Link color="inherit" href="/partners">
              {"HBOMax "}
            </Link>
          ) : (
            <Button
              color="primary"
              variant="text"
              href={`/partners/${clientId}`}
            >
              {clientName}
            </Button>
          )}
          <Link color="inherit" href={`/projects/${partnerId}`}>
            {partnerName}
          </Link>
          <Typography color="inherit">{projectName}</Typography>
          <Typography color="textPrimary">Assets</Typography>
        </Breadcrumbs>
      </div>
    );
  }
};

export default TheBreadcrumb;
