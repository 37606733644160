import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Strapi from "strapi-sdk-javascript/build/main";
import {
  setToken,
  setUserId,
  setPolicy,
  setClient,
  apiurl,
} from "../utils/utils";
import ToastMessage from "../utils/ToastMessage";

// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class Signin extends Component {
  // Component state
  state = {
    username: "",
    password: "",
    toast: false,
    toastMessage: "",
    loading: false,
  };

  //Handling Username and Password Test Field Changes
  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { username, password } = this.state;

    if (this.isFormEmpty(this.state)) {
      this.showToast("Fill in all fields");
      return;
    }

    //signup a user

    try {
      this.setState({ loading: true });
      const response = await strapi.login(username, password);
      this.setState({ loading: false });
      // put token (to manage user session) in local storage
      setToken(response.jwt);
      setUserId(response.user.id);
      setPolicy(response.user.role.name);
      setClient(response.user.clients[0].clientname);
      console.log(response.user.clients[0].clientname);
      response.user.role.name === "trailerparkuser"
        ? (window.location = "/clients")
        : (window.location = "/partners");
    } catch (error) {
      console.error(error);
      // set loading to false
      this.setState({ loading: false });
      // show error message withen toast message
      this.showToast("Invalid Username or Password");
    }
  };

  redirectUser = (path) => this.props.history.push(path);

  showToast = (toastMessage) => {
    this.setState({ toast: true, toastMessage });
    setTimeout(() => this.setState({ toast: false, toastMessage: "" }), 5000);
  };

  isFormEmpty = ({ username, password }) => {
    return !username || !password;
  };

  render() {
    const { toastMessage, toast } = this.state;
    return (
      <Container component="main" maxWidth="xs">
        <ToastMessage show={toast} message={toastMessage} />
        <CssBaseline />
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: "10px",
            }}
            noValidate
            onSubmit={this.handleSubmit}
          >
            <TextField
              style={{ backgroundColor: "#111", borderRadius: "5px" }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username / Email address"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={this.handleChange}
            />
            <TextField
              style={{ backgroundColor: "#111", borderRadius: "5px" }}
              variant="filled"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange}
            />
            <Button
              type="submit"
              disabled={this.state.loading}
              fullWidth
              variant="contained"
              color="primary"
              style={{ marginTop: "25px" }}
            >
              {this.state.loading === true ? (
                <Typography>Signing In ... </Typography>
              ) : (
                <Typography>Sign In</Typography>
              )}
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

export default Signin;
