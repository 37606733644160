import React, { Component, Fragment } from "react";
import { Box, Fab } from "@material-ui/core";
import {
  getPolicy,
  clearToken,
  getUserId,
  apiurl,
  getClient,
} from "../utils/utils";
import AddIcon from "@material-ui/icons/Add";
import Strapi from "strapi-sdk-javascript/build/main";
import TheBreadcrumb from "../utils/TheBreadcrumb";
import PartnerCard from "../utils/PartnerCard";
import SideBar from "../SideBar";
import CheckBoxOutlineBlankTwoToneIcon from "@material-ui/icons/CheckBoxOutlineBlankTwoTone";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class TpPartners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      partners: [],
      currentPolicy: getPolicy(),
      currentUser: getUserId(),
      client: [],
      clientId: "",
    };
  }

  async componentDidMount() {
    console.log(this.state.currentPolicy);
    console.log(this.props.match.params.clientId);
    try {
      const partnersData = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            users(where:{id: "${this.state.currentUser}"}){
              clients(where: {id: "${this.props.match.params.clientId}"}){
                id
                clientname
                partners (where: {archive: false}){
                  id
                  partnername
                  updatedAt
                  createdAt
                  publish
                  partnerlogo{
                    url
                  }
                  projects{
                    projectname
                  }
                }
              }
            }
          }
          `,
        },
      });
      console.log(partnersData.data.users[0].clients[0].clientname);
      this.setState({
        clientId: partnersData.data.users[0].clients[0].id,
        client: partnersData.data.users[0].clients[0].clientname,
        partners: partnersData.data.users[0].clients[0].partners.sort(function (
          a,
          b
        ) {
          if (a.partnername.toLowerCase() < b.partnername.toLowerCase())
            return -1;
          if (a.partnername.toLowerCase() > b.partnername.toLowerCase())
            return 1;
          return 0;
        }),
      });
    } catch (error) {
      console.error(error);
    }

    console.log(this.state.partners);
  }

  emptyPartner() {
    clearToken();
    window.location = "/signin";
  }

  render() {
    return (
      <Box display="flex" borderBottom={0.1} minHeight="83vh">
        {/** Removing Side Bar
        <Box borderRight={0.1} style={{ width: "240px" }}>
          <SideBar
            partners={
              this.state.partners === null ||
              this.state.currentPolicy === "hbomaxuser"
                ? ""
                : this.state.currentPolicy === "peacockuser"
                ? ""
                : this.state.partners
            }
            projects={""}
            partner={""}
            project={""}
            client={this.state.client}
            clientId={this.state.clientId}
          />
        </Box>*/}
        <Box
          display="flex"
          flexDirection="column"
          borderBottom={0.1}
          width="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            p={2}
          >
            {this.state.currentPolicy === "trailerparkuser" ? (
              <Fragment>
                <Box flexGrow={1} display="flex">
                  <Fab
                    onClick={() =>
                      (window.location = `/addpartner/${this.state.clientId}`)
                    }
                    color="primary"
                    aria-label="add"
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                </Box>
                <Box flexGrow={2}>
                  <TheBreadcrumb
                    partnerName={"partners"}
                    projectName={"Projects"}
                    assetName={"Assets"}
                    clientName={this.state.client}
                    clientId={this.state.clientId}
                  />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <TheBreadcrumb
                  partnerName={"partners"}
                  projectName={"Projects"}
                  assetName={"Assets"}
                  clientName={this.state.client}
                  clientId={this.state.clientId}
                />
              </Box>
            )}
          </Box>
          {this.state.currentPolicy === "trailerparkuser" ? (
            <Box m={2} p={2} display="flex" alignItems="center">
              <CheckBoxOutlineBlankTwoToneIcon style={{ color: "#646464" }} />
              {" Published"}
              <CheckBoxOutlineBlankTwoToneIcon style={{ color: "#ffff33" }} />
              {" Not Published "}
            </Box>
          ) : (
            <Fragment />
          )}
          <Box
            width="85vw"
            display="flex"
            p={3}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {this.state.partners === null || getClient() === null
              ? this.emptyPartner()
              : this.state.currentPolicy === "trailerparkuser"
              ? this.state.partners.map((partner) => (
                  <Box
                    m={2}
                    key={partner.id}
                    style={
                      partner.publish === false
                        ? { border: "solid 1px #ffff33", borderRadius: "5px" }
                        : { border: "solid 0px #fff" }
                    }
                  >
                    <PartnerCard
                      clientId={this.state.clientId}
                      partnerId={partner.id}
                      partnerName={partner.partnername}
                      creationData={partner.createdAt}
                      updateDate={partner.updatedAt}
                      partnerLogo={
                        partner.partnerlogo !== null
                          ? `${partner.partnerlogo.url}`
                          : `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`
                      }
                      numberOfProjects={partner.projects.length}
                      publishStatus={partner.publish.toString()}
                    />
                  </Box>
                ))
              : this.state.currentPolicy === "hbomaxuser"
              ? this.state.partners
                  .filter((partner) => partner.publish === true)
                  .map((par) => (
                    <Box p={1} m={1} key={par.id}>
                      <PartnerCard
                        partnerId={par.id}
                        partnerName={par.partnername}
                        creationData={par.createdAt}
                        updateDate={par.updatedAt}
                        partnerLogo={
                          par.partnerlogo !== null
                            ? `${par.partnerlogo.url}`
                            : `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`
                        }
                        numberOfProjects={par.projects.length}
                      />
                    </Box>
                  ))
              : null}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default TpPartners;
