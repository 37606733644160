import React, { Component } from "react";
import { Box } from "@material-ui/core";
import { getPolicy, clearToken, getUserId, apiurl } from "../utils/utils";
import Strapi from "strapi-sdk-javascript/build/main";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      currentPolicy: getPolicy(),
      currentUser: getUserId(),
    };
  }
  async componentDidMount() {
    try {
      const clientsData = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            clients{
              id
              clientname
              users{
                id
                username
                role{
                  name
                }
              }
              clientlogo{
                id
                url
              }
            }
          }
          `,
        },
      });
      this.setState({
        clients: clientsData.data.clients.sort(function (a, b) {
          if (a.clientname.toLowerCase() < b.clientname.toLowerCase())
            return -1;
          if (a.clientname.toLowerCase() > b.clientname.toLowerCase()) return 1;
          return 0;
        }),
      });
    } catch (error) {
      console.error(error);
    }
  }

  emptyPartner() {
    clearToken();
    window.location = "/signin";
  }

  render() {
    console.log(this.state.clients);
    return (
      <Box display="flex" borderBottom={0.1} minHeight="88vh">
        <Box
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRight={0.1}
          style={{ width: "240px", backgroundColor: "#222" }}
        >
          <Box
            style={{
              transform: "rotate(-90deg)",
              fontSize: "50px",
              width: "400px",
              color: "#111e",
            }}
          >
            {"Available Clients"}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          borderBottom={0.1}
          width="100%"
        >
          <Box
            width="85vw"
            display="flex"
            p={3}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {this.state.clients.map((client) =>
              client.users.map((user) =>
                this.state.currentUser === user.id ? (
                  <Box
                    border={0.1}
                    borderColor="#444"
                    margin={1}
                    key={client.id}
                  >
                    <Card
                      style={{
                        backgroundColor: "#111",
                        maxWidth: "345px",
                      }}
                    >
                      <CardActionArea
                        onClick={() =>
                          (window.location = `/partners/${client.id}`)
                        }
                      >
                        <CardMedia
                          style={{ height: "100px", width: "345px" }}
                          image={client.clientlogo.url}
                          title={client.clientname}
                        />
                        <CardContent>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            color="textSecondary"
                          >
                            {client.clientname}
                          </Typography>
                          <Typography variant="body2" component="p">
                            You are currently have access to this client
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                ) : null
              )
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Clients;
