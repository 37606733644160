import React, { useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/Home";
import HBOMaxLogo from "./assets/HBOMax_Logo.png";
import PeacockLogo from "./assets/Peacock_Logo.png";
import HuluLogo from "./assets/Hulu_Logo.jpg";
import GoodingLogo from "./assets/gooding_Logo.jpg";
import AmazonLogo from "./assets/Amazon_Smile_Logo.jpg";
import SyntaxLogo from "./assets/Syntax_Logo.jpg";
import RiotLogo from "./assets/Riot_Logo.jpg";
import { NavLink, withRouter } from "react-router-dom";
import {
  getToken,
  clearToken,
  clearUserId,
  getUserId,
  getClient,
  getPolicy,
  apiurl,
} from "./utils/utils";
import axios from "axios";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

class Header extends React.Component {
  state = {
    token: getToken(),
    userId: getUserId(),
    client: getClient(),
    firstname: "",
    profilePicture: "",
  };
  async componentDidMount() {
    try {
      const response = await axios.get(`${apiurl}/users/${this.state.userId}`, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });

      this.setState({
        firstname: response.data.firstname,
        profilePicture:
          response.data.image === undefined || response.data.image === null
            ? `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/tempProfile_2146704100.jpeg`
            : `${response.data.image.url}`,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleSignout = () => {
    clearToken();
    clearUserId();
    this.setState({
      firstname: "",
      profilePicture: "",
    });
    //this.props.history.push("/");
    window.location = "/";
  };
  goToProfile = () => {
    this.props.history.push("/profile");
  };
  render() {
    return getToken() !== null ? (
      <AuthHeader
        handleSignout={this.handleSignout}
        goToProfile={this.goToProfile}
        firstname={this.state.firstname}
        profilePicture={this.state.profilePicture}
        client={this.state.client}
      />
    ) : (
      <UnAuthHeader />
    );
  }
}

const useStyle = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
  },

  tpheader: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    textTransform: "uppercase",
  },
  logoText: {
    letterSpacing: "3.2px",
    fontSize: "20px",
    textTransform: "uppercase",
  },
}));

const AuthHeader = ({
  handleSignout,
  goToProfile,
  profilePicture,
  firstname,
  client,
}) => {
  const classes = useStyle();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      bgcolor="background.paper"
      justifyContent="flex-end"
      borderBottom={0.1}
      style={{
        minWidth: "100%",
      }}
    >
      <Box
        className={classes.tpheader}
        borderColor="text.primary"
        display="flex"
        flexGrow={1}
      >
        {getPolicy() === "trailerparkuser" ? (
          <Box ml={1} mb={3}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<HomeIcon />}
              onClick={() => (window.location = `/clients`)}
            >
              Clients
            </Button>
          </Box>
        ) : (
          <NavLink to="/partners">
            {client === "HBOMax" ? (
              <img src={HBOMaxLogo} width="270px" alt="" />
            ) : client === "Peacock" ? (
              <img src={PeacockLogo} width="270px" alt="" />
            ) : client === "Hulu" ? (
              <img src={HuluLogo} width="270px" alt="" />
            ) : client === "Gooding & Company" ? (
              <img src={GoodingLogo} width="270px" alt="" />
            ) : client === "Amazon" ? (
              <img src={AmazonLogo} width="270px" alt="" />
            ) : client === "Syntax" ? (
              <img src={SyntaxLogo} width="270px" alt="" />
            ) : client === "Riot" ? (
              <img src={RiotLogo} width="270px" alt="" />
            ) : null}
          </NavLink>
        )}
      </Box>

      <Box
        display="flex"
        p={1}
        borderLeft={0.1}
        flexDirection="col"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Avatar
              style={{ width: "30px", height: "30px" }}
              alt={firstname}
              src={profilePicture}
            />
            <Typography variant="caption">{firstname}</Typography>
          </Box>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={goToProfile}>Manage Profile</MenuItem>
          <MenuItem onClick={handleSignout}>Logout</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

const UnAuthHeader = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      bgcolor="background.paper"
      justifyContent="flex-end"
    ></Box>
  );
};

export default withRouter(Header);
