import React, { Component } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import AssetTabs from "../utils/AssetTabs";
import Comments from "../utils/Comments";
import { apiurl } from "../utils/utils";
import Strapi from "strapi-sdk-javascript/build/main";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class AssetView extends Component {
  state = {
    assetId: this.props.match.params.assetId,
    assetData: "",
    projectData: "",
    partnerData: "",
    assetType: "",
    width: "",
    height: "",
    rounds: [],
    status: "",
  };
  async componentDidMount() {
    const { assetId } = this.props.match.params;
    try {
      const assetData = await strapi.request("post", "graphql", {
        data: {
          query: `
            query{
                asset(id:"${assetId}"){
                    id
                    assetname
                    status
                    publish
                    updatedAt
                    approvedround
                    project{
                        id
                        projectname
                        partner{
                          id
                          partnername
                        }
                    }
                    rounds{
                        id
                        name
                        width
                        height
                        mime
                        url
                        alternativeText
                    }
                }
            }
            `,
        },
      });
      this.setState({
        assetData: assetData.data.asset,
        projectData: assetData.data.asset.project,
        partnerData: assetData.data.asset.project.partner,
        assetType: assetData.data.asset.rounds[0].mime,
        width: assetData.data.asset.rounds[0].width,
        height: assetData.data.asset.rounds[0].height,
        rounds: assetData.data.asset.rounds,
        status: assetData.data.asset.status,
      });
    } catch (error) {}
  }
  render() {
    return (
      <Box display="flex" borderBottom={0.1} minHeight="83vh">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderRight={0.1}
          p={1}
          style={{ width: "300x", backgroundColor: "#222" }}
        >
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            fullWidth
            onClick={() => {
              window.location = `/projects/${this.state.partnerData.id}`;
            }}
          >
            {"<< "}
            {this.state.partnerData.partnername}
          </Button>
          <br />
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            fullWidth
            onClick={() => {
              window.location = `/assets/${this.state.projectData.id}`;
            }}
          >
            {"<< "}
            {this.state.projectData.projectname}
          </Button>
        </Box>
        <Box
          width="100%"
          minHeight="80vh"
          display="flex"
          flexDirection="column"
          borderBottom={0.1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="left"
            style={{ backgroundColor: "#222", width: "100%" }}
            p={2}
          >
            <Typography>
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Partner Name: </strong>
              </span>
              {this.state.partnerData.partnername}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Project Name: </strong>
              </span>
              {this.state.projectData.projectname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Asset Name: </strong>
              </span>
              {this.state.assetData.assetname}
            </Typography>

            <Typography>
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Asset Type: </strong>
              </span>
              {this.state.assetType}
            </Typography>
            <Typography>
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Width: </strong>
              </span>
              {this.state.width}
            </Typography>
            <Typography>
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Height: </strong>
              </span>
              {this.state.height}
            </Typography>
            <Typography>
              <span style={{ color: "rgba(255, 81, 0, 1)" }}>
                <strong>Status: </strong>
              </span>
              {this.state.status === "inreview" ? (
                <span style={{ color: "#ffff33" }}>In Review</span>
              ) : (
                <span style={{ color: "#33cc33" }}>Approved</span>
              )}
            </Typography>
          </Box>
          <Box>
            <AssetTabs
              projectId={this.state.projectData.id}
              assetId={this.state.assetData.id}
              assetName={this.state.assetData.assetname}
              rounds={this.state.rounds}
              assetStatus={this.state.status}
              approvedR={this.state.assetData.approvedround}
            />
          </Box>
          <Box m={2}>
            <Comments assetId={this.state.assetId} />
          </Box>
        </Box>
      </Box>
    );
  }
}
export default AssetView;
