import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  typography: {
    fontFamily: ["MyFont", "sans-serif"].join(","),
  },

  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "rgba(0, 0, 0, 1)",
      default: "rgba(0, 0, 0, 1)",
    },
    primary: {
      light: "rgba(255, 116, 51, 1)",
      main: "rgb(115, 115, 115)",
      dark: "rgba(204, 65, 0, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      light: "rgba(133, 133, 133, 1)",
      main: "rgba(41, 41, 41, 1)",
      dark: "rgba(18, 18, 18, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(252, 252, 252, 0.87)",
      secondary: "rgba(255, 81, 0, 1)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export default theme;
