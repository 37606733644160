import React, { Component } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Switch,
  CardMedia,
} from "@material-ui/core";
import Strapi from "strapi-sdk-javascript/build/main";
import axios from "axios";
import { getToken, getPolicy, apiurl } from "./utils";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class UpdatePartner extends Component {
  state = {
    clientId: "",
    partnername: "",
    partnerlogo: `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`,
    publishStatus: false,
    token: getToken(),
    partnerLogoId: "",
  };
  async componentDidMount() {
    try {
      const partner = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            partner(id:"${this.props.match.params.partnerId}"){
              id
              partnername
              publish
              clients{
                id
              }
              partnerlogo{
                id
                url
              }
            }
          }
          `,
        },
      });
      this.setState({
        clientId: partner.data.partner.clients[0].id,
        partnername: partner.data.partner.partnername,
        partnerlogo:
          partner.data.partner.partnerlogo === null
            ? `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`
            : `${partner.data.partner.partnerlogo.url}`,
        publishStatus: partner.data.partner.publish,
        partnerLogoId:
          partner.data.partner.partnerlogo === null
            ? "5ef699c95c9d7e17fa94be1b"
            : partner.data.partner.partnerlogo.id,
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleLogoChange = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const data = new FormData();
    data.append("files", file);
    data.append("ref", "partners");
    data.append("refId", this.props.match.params.partnerId);
    data.append("field", "partnerlogo");

    const upload_logo = await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    console.log(upload_logo.data);
    this.setState({ partnerlogo: `${upload_logo.data[0].url}` });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      partnername: this.state.partnername,
      publish: this.state.publishStatus,
    };

    try {
      await axios.put(
        `${apiurl}/partners/${this.props.match.params.partnerId}`,
        data,
        {
          headers: { Authorization: `Bearer ${this.state.token}` },
        }
      );
    } catch (error) {}
    getPolicy() === "trailerparkuser"
      ? (window.location = `/partners/${this.state.clientId}`)
      : (window.location = "/partners");
  };

  handlePublishChange = (event) => {
    console.log(event.target.checked);

    this.setState({
      publishStatus: event.target.checked,
    });
  };

  handleNameChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRight={0.1}
          style={{ width: "340px", backgroundColor: "#222", height: "85vh" }}
        >
          <Typography color="primary"> Updating Partner info</Typography>
          {/* <SideBar partners={""} projects={""} partner={""} project={""} /> */}
        </Box>

        <Box width="100%" height="85vh" display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            pt={2}
            pb={2}
          >
            <Box>
              <Typography color="primary"> Updating Partner info</Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            m={3}
            p={2}
            flexDirection="column"
            style={{
              backgroundColor: "#222",
              width: "70ch",
              borderRadius: "10px",
            }}
          >
            <Box>
              <h2>Updating {this.state.partnername} details </h2>
            </Box>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <TextField
                id="partner-name"
                label={"Partner Name"}
                value={this.state.partnername}
                name="partnername"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />
              <Typography>Logo</Typography>
              <CardMedia
                image={this.state.partnerlogo}
                title={this.state.partnername}
                width="400"
                height="300"
                style={{ height: "200px" }}
              />
              <Box pt={3}>
                <input
                  accept="image/*"
                  id="logo-button"
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.handleLogoChange}
                />
                <label htmlFor="logo-button">
                  <Button variant="outlined" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </Box>
              <Box pt={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.publishStatus}
                      onChange={this.handlePublishChange}
                      name="publishStatus"
                      color="primary"
                    />
                  }
                  label="Publish"
                />
              </Box>
              <Box pt={2} display="flex" flexDirection="row">
                <Box>
                  <Button variant="contained" color="primary" type="submit">
                    Update
                  </Button>
                </Box>
                <Box pl={2}>
                  {getPolicy() === "trailerparkuser" ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        (window.location = `/partners/${this.state.clientId}`)
                      }
                    >
                      Close
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => (window.location = "/partners")}
                    >
                      Close
                    </Button>
                  )}
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default UpdatePartner;
