import { Box } from "@material-ui/core";
import React from "react";

export default function HTMLAssets(props) {
  function uploadFile(e) {
    console.log(e.target.files);
  }

  return (
    <Box margin={4} padding={4} style={{ backgroundColor: "#333" }}>
      <input type="file" onChange={uploadFile()} />
    </Box>
  );
}
