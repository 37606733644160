import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import theme from "./theme";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Signin from "./components/Signin";
import Partners from "./components/Partners";
import TpPartners from "./components/TpPartners";
import Projects from "./components/Projects";
import Assets from "./components/Assets";
import UserProfile from "./components/UserProfile";
import AssetView from "./components/AssetView";
import Clients from "./components/Clients";
import { getToken } from "./utils/utils";
import UpdatePartner from "./utils/UpdatePartner";
import AddNewPartner from "./utils/AddNewPartner";
import AddNewAsset from "./utils/AddNewAsset";
import AddNewHtml5Asset from "./utils/AddNewHtml5Asset";
import TopBar from "./TopBar";
import Header from "./Header";
import WebFont from "webfontloader";
//import Iframe from "./components/Iframe";
import HTMLAssets from "./utils/HTMLAssets";

WebFont.load({
  google: {
    families: ["Titillium Web:300,400,700", "sans-serif"],
  },
});

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      getToken() !== null ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const token = getToken();

const Root = () => (
  <Router>
    <React.Fragment>
      <TopBar />
      <Header />
      <Switch>
        {token !== null ? (
          <PrivateRoute component={Partners} exact path="/" />
        ) : (
          <Route component={Signin} exact path="/" />
        )}
        <Route component={Signin} exact path="/signin" />

        {/* -------------- Test Routing  ------------  */}
        {/* <Route component={Iframe} path="/iframtest" /> */}

        {/* -------------- Clients Routing  ------------  */}
        <PrivateRoute component={Clients} path="/clients" />
        <PrivateRoute component={TpPartners} path="/partners/:clientId" />

        {/* -------------- Partners Routing  ------------  */}
        <PrivateRoute component={Partners} path="/partners" />
        <PrivateRoute component={Projects} path="/projects/:partnerId" />
        <PrivateRoute
          component={UpdatePartner}
          path="/editpartner/:partnerId"
        />
        <PrivateRoute component={AddNewPartner} path="/addpartner/:clientId" />

        {/* -------------- Asset Routing  ------------  */}
        <PrivateRoute component={Assets} path="/assets/:projectId" />
        <PrivateRoute component={AddNewAsset} path="/addassets/:projectId" />
        <PrivateRoute
          component={AddNewHtml5Asset}
          path="/addhtml5assets/:projectId"
        />
        <PrivateRoute component={AssetView} path="/shareassets/:assetId" />
        <PrivateRoute component={HTMLAssets} path="/addhtmlassets" />

        {/* -------------- Profile Routing  ------------  */}
        <PrivateRoute component={UserProfile} path="/profile/" />
      </Switch>
    </React.Fragment>
  </Router>
);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Box
      style={{
        backgroundColor: "#000",
        minWidth: "fit-content",
      }}
    >
      <Root />
    </Box>
  </ThemeProvider>,
  document.querySelector("#root")
);
