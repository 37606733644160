import React, { Component } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Switch,
  CardMedia,
} from "@material-ui/core";
import axios from "axios";
import Iframe from "react-iframe";
import { getToken, apiurl } from "./utils";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

export default class AddNewHtml5Partner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetname: "",
      aws3url: "",
      aws3urlId: "",
      bannerwidth: "",
      bannerheight: "",
      urlSaved: false,
      placeholder: `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`,
      publishStatus: false,
      token: getToken(),
      projectId: this.props.match.params.projectId,
    };
  }

  handleLogoChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const data = new FormData();

    data.append("files", file);

    const upload_logo = await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    console.log(upload_logo.data);
    this.setState({
      partnerlogo: `${upload_logo.data[0].url}`,
      partnerLogoId: upload_logo.data[0].id,
    });
    console.log(this.state);
  };

  createAsset = async (event) => {
    event.preventDefault();

    const data = {
      assetname: this.state.assetname,
      archive: false,
      publish: this.state.publishStatus,
      status: "inreview",
      type: "html5",
      bannerwidth: this.state.bannerwidth,
      bannerheight: this.state.bannerheight,
      project: this.state.projectId,
      html_version_links: [{ _id: this.state.aws3urlId }],
    };

    try {
      await axios.post(`${apiurl}/assets`, data, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
      this.props.history.push(`/assets/${this.state.projectId}`);
    } catch (error) {
      console.error(error);
    }
  };

  handlePublishChange = (event) => {
    console.log(event.target.checked);

    this.setState({
      publishStatus: event.target.checked,
    });
  };

  handleNameChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  saveUrl = async () => {
    const data = {
      htmllink: this.state.aws3url,
      roundnumber: "01",
    };

    const urlId = await axios({
      method: "POST",
      url: `${apiurl}/html-version-links`,
      data,
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    console.log(urlId.data.id);
    this.setState({
      aws3urlId: urlId.data.id,
      urlSaved: true,
    });
  };

  deleteUrl = async () => {
    const urlId = await axios({
      method: "DELETE",
      url: `${apiurl}/html-version-links/${this.state.aws3urlId}`,
      headers: {
        Authorization: `Bearer ${this.state.token}`,
      },
    });
    console.log("Deleted", urlId);
    this.setState({
      aws3urlId: "",
      urlSaved: false,
    });
  };

  render() {
    return (
      <Box display="flex" borderBottom={0.1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRight={0.1}
          style={{ width: "240px", backgroundColor: "#222", height: "auto" }}
        >
          <Typography color="primary"> Adding new HTML5 asset </Typography>
          {/* <SideBar partners={""} projects={""} partner={""} project={""} /> */}
        </Box>

        <Box width="100%" height="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            pt={2}
            pb={2}
          >
            <Box>
              <Typography color="primary"> Adding new HTML5 asset </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            m={3}
            p={2}
            flexDirection="column"
            style={{
              backgroundColor: "#222",
              minwidth: "500px",
              borderRadius: "10px",
            }}
          >
            <Box>
              <h2>Adding New HTML5 asset </h2>
            </Box>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <TextField
                id="asset-name"
                label={"Asset Name"}
                value={this.state.assetname}
                name="assetname"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />
              <TextField
                id="asset-url"
                label={"AWS S3 URL"}
                value={this.state.aws3url}
                name="aws3url"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />
              <TextField
                id="asset-width"
                label={"Banner Width"}
                value={this.state.bannerwidth}
                name="bannerwidth"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />
              <TextField
                id="asset-height"
                label={"Banner Height"}
                value={this.state.bannerheight}
                name="bannerheight"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />

              <Typography>HTML5 Banner Preview</Typography>
              <br />
              {this.state.aws3url === "" ? (
                <CardMedia
                  image={this.state.placeholder}
                  title={this.state.partnername}
                  width="400"
                  height="400"
                  style={{ height: "300px" }}
                />
              ) : (
                <Iframe
                  url={this.state.aws3url}
                  width={this.state.bannerwidth}
                  height={this.state.bannerheight}
                />
              )}
              <Box pt={3}>
                {this.state.urlSaved === false ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={this.saveUrl}
                  >
                    Save current URL
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="default"
                    component="span"
                    onClick={this.deleteUrl}
                  >
                    URL Saved (Click To Undo)
                  </Button>
                )}
              </Box>
              <Box pt={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.publishStatus}
                      onChange={this.handlePublishChange}
                      name="publishStatus"
                      color="primary"
                    />
                  }
                  label="Publish"
                />
              </Box>
              <Box pt={2} display="flex" flexDirection="row">
                <Box>
                  {this.state.aws3urlId === "" ||
                  this.state.assetname === "" ||
                  this.state.bannerheight === "" ||
                  this.state.bannerwidth === "" ||
                  this.state.aws3url === "" ? null : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={this.createAsset}
                    >
                      Save
                    </Button>
                  )}
                </Box>
                <Box pl={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      (window.location = `/partners/${this.state.clientId}`)
                    }
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    );
  }
}
