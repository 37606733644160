import React, { Component } from "react";
import { getUserId, getToken, apiurl } from "../utils/utils";
import UpLoadProgressBar from "../utils/UpLoadProgressBar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  Box,
  Typography,
  TextField,
  CardMedia,
  Button,
} from "@material-ui/core";
import axios from "axios";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: getToken(),
      userId: getUserId(),
      firstname: "",
      lastname: "",
      email: "",
      profilePictureId: "5ef9b29b3b7c3f238910f3c7",
      profilePicture: `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/tempProfile_2146704100.jpeg`,
      showProgressBar: false,
      profileUpdated: false,
      uploadPrecentage: 0,
    };
  }
  async componentDidMount() {
    try {
      const response = await axios.get(`${apiurl}/users/${this.state.userId}`, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
      this.setState({
        firstname: response.data.firstname,
        lastname: response.data.lastname,
        email: response.data.email,
        profilePicture:
          response.data.image === undefined || response.data.image === null
            ? `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/tempProfile_2146704100.jpeg`
            : `${response.data.image.url}`,
      });
    } catch (error) {
      console.error(error.response);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      image: this.state.profilePictureId,
    };
    try {
      await axios.put(`${apiurl}/users/${this.state.userId}`, data, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
      this.setState({ profileUpdated: true });
      setTimeout(() => this.setState({ profileUpdated: false }), 3000);
    } catch (error) {}
  };

  handleChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleLogoChange = async (event) => {
    event.preventDefault();
    this.setState({ showProgressBar: true });
    const userNewProfilePicutre = event.target.files[0];
    const data = new FormData();
    data.append("files", userNewProfilePicutre);

    const profile_pic = await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.state.token}`,
      },
      onUploadProgress: (progressEvent) => {
        this.setState({
          uploadPrecentage: parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          ),
        });
        setTimeout(() => this.setState({ showProgressBar: false }), 1000);
      },
    });
    this.setState({
      profilePictureId: profile_pic.data[0].id,
      profilePicture: profile_pic.data[0].url,
    });
  };

  closeProfile = () => {
    window.location = "/partners";
  };

  render() {
    const { firstname, lastname, email, profilePicture } = this.state;
    return (
      <React.Fragment>
        <Box display="flex" borderBottom={0.1}>
          {/** Removing Side Bar
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRight={0.1}
            style={{ width: "240px", backgroundColor: "#222", height: "85vh" }}
          >
            <Typography color="primary">Managing User Profile</Typography>
            <SideBar partners={""} projects={""} partner={""} project={""} /> 
          </Box>*/}
          <Box>
            <Box
              style={{
                width: "700px",
                borderRadius: "10px",
                backgroundColor: "#222",
              }}
              margin={3}
              padding={2}
            >
              {this.state.profileUpdated === true ? (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  mb={2}
                  p={2}
                  style={{
                    color: "#fff",
                    border: "solid 1px #ff5100",
                    backgroundColor: "#222",
                  }}
                >
                  <CheckCircleIcon color="primary" />
                  <Box>Profile successfully updated!</Box>
                </Box>
              ) : null}
              <Typography align="center" color="primary" variant="h5">
                {firstname}'s Profile Page
              </Typography>
              <Typography align="left" color="textPrimary" variant="h6">
                Profile Picture
              </Typography>
              <form onSubmit={this.handleSubmit}>
                <CardMedia
                  image={`${profilePicture}`}
                  title="Profile Picture"
                  style={{
                    height: "200px",
                    width: "200px",
                    marginTop: "15px",
                    marginBottom: "25px",
                  }}
                />
                {this.state.showProgressBar === true ? (
                  <UpLoadProgressBar
                    uploadPrecentage={this.state.uploadPrecentage}
                  />
                ) : null}

                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="logo-button"
                  type="file"
                  //multiple
                  onChange={this.handleLogoChange}
                />
                <label htmlFor="logo-button">
                  <Button variant="outlined" color="primary" component="span">
                    Change/upload Picture
                  </Button>
                </label>
                <br />
                <br />
                <br />
                <Box display="flex" flexDirection="row">
                  <Box pb={3} width={400}>
                    <TextField
                      id="firstname"
                      name="firstname"
                      label="First Name"
                      onChange={this.handleChange}
                      value={firstname}
                      fullWidth
                    />
                    <Box mt={2}>
                      <TextField
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        value={lastname}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </Box>
                    <Box mt={2}>
                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        value={email}
                        onChange={this.handleChange}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Box>
                <Button type="submit" color="primary" variant="contained">
                  Update
                </Button>
                <Button
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={this.closeProfile}
                  color="primary"
                  variant="contained"
                >
                  Close
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

export default UserProfile;
