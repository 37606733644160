import React, { Component, Fragment } from "react";
import {
  Box,
  Typography,
  Fab,
  TextField,
  Button,
  Badge,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SideBar from "../SideBar";
import Strapi from "strapi-sdk-javascript/build/main";
import TheBreadcrumb from "../utils/TheBreadcrumb";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import { getPolicy, getToken, apiurl } from "../utils/utils";
import AssetTabs from "../utils/AssetTabs";
import Html5AssetTabs from "../utils/Html5AssetTabs";
import axios from "axios";
import Comments from "../utils/Comments";

// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class Assets extends Component {
  state = {
    partnername: "",
    partnerlogo: "",
    partnerId: "",
    assets: [],
    userPolicy: getPolicy(),
    dialog: false,
    projects: "",
    clientName: "",
    clientId: "",
    comments: [],
    showComments: "none",
    commentButton: true,
    anchorEl: null,
  };

  async componentDidMount(projectId) {
    projectId === undefined
      ? (projectId = this.props.match.params.projectId)
      : (projectId = projectId);

    try {
      const projectsData = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            project(id:"${projectId}"){
              id
              projectname
              partner{
                id
                partnername
                partnerlogo{
                  url
                }
                clients{
                  id
                  clientname
                }
                projects(where: { archive: false }){
                  id
                  projectname
                }
              }
              assets(where: { archive: false }){
                id
                assetname
                status
                publish
                updatedAt
                approvedround
                type
                bannerwidth
                bannerheight
                html_version_links{
                  id
                  htmllink
                  roundnumber
                }
                rounds{
                  id
                  width
                  height                  
                  url
                  alternativeText
                }
              }
            }
          }
          `,
        },
      });
      this.setState({
        clientName: projectsData.data.project.partner.clients[0].clientname,
        clientId: projectsData.data.project.partner.clients[0].id,
        assets: projectsData.data.project.assets,
        projectname: projectsData.data.project.projectname,
        partnerId: projectsData.data.project.partner.id,
        partnername: projectsData.data.project.partner.partnername,
        projects: projectsData.data.project.partner.projects,
        partnerlogo:
          projectsData.data.project.partner.partnerlogo === null
            ? "https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png"
            : projectsData.data.project.partner.partnerlogo.url,
      });
    } catch (error) {
      console.error(error);
    }

    try {
      const comments = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            comments(sort: "updatedAt:desc"){
              id
              assetcomment
              updatedAt
              user{
                id
                username
              }
              asset{
                id
                assetname
                project{
                  id
                  projectname
                }
              }
            }
          }
          `,
        },
      });
      this.setState({
        comments: comments.data.comments.filter(
          (comment) =>
            comment.asset.project.id === this.props.match.params.projectId
        ),
      });
      console.log(this.state.comments);

      //console.log(this.state.comments);
    } catch (error) {
      console.error(error);
    }
  }

  updateTheState = async (projectId) => {
    try {
      const projectsData = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            project(id:"${projectId}"){
              id
              projectname
              partner{
                id
                partnername
                partnerlogo{
                  url
                }
              }
              assets(where: { archive: false }){
                id
                assetname
                status
                publish
                updatedAt
                type
                bannerwidth
                bannerheight
                html_version_links{
                  id
                  htmllink
                  roundnumber
                }
                rounds{
                  id
                  name
                  width
                  height
                  mime
                  url
                  alternativeText
                }
              }
            }
          }
          `,
        },
      });
      this.setState({
        assets: projectsData.data.project.assets,
        projectname: projectsData.data.project.projectname,
        partnerId: projectsData.data.project.partner.id,
        partnername: projectsData.data.project.partner.partnername,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleAssetUpdate = async (assetId, assetName, assetStatus, publish) => {
    const data = {
      assetname: assetName,
      status: assetStatus,
      publish: publish,
    };

    const token = getToken();
    await axios.put(`${apiurl}/assets/${assetId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    this.componentDidMount();
  };

  handleDelete = async (assetId) => {
    const data = {
      archive: true,
    };

    const token = getToken();
    await axios.put(`${apiurl}/assets/${assetId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    this.componentDidMount();
  };

  handleMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    return (
      <Box display="flex" borderBottom={0.1} minHeight="83vh">
        {/** Removing Side Bar
        <Box
          borderRight={0.1}
          borderBottom={0.1}
          style={{ width: "240px", overflow: "hidden" }}
        >
          <SideBar
            partners={""}
            project={this.state.projectname}
            partner={this.state.partnername}
            partnerId={this.state.partnerId}
            partnerlogo={this.state.partnerlogo}
            projects={this.state.projects}
            client={this.state.clientName}
            clientId={this.state.clientId}
          />
        </Box>*/}
        <Box width="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            pt={2}
            pb={2}
          >
            {this.state.userPolicy === "trailerparkuser" ? (
              <Fragment>
                <Box pl={2} flexGrow={1} display="flex">
                  <Fab
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                    color="primary"
                    aria-label="add"
                    size="small"
                  >
                    <AddIcon fontSize="small" />
                  </Fab>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    <MenuItem
                      onClick={() => {
                        this.props.history.push(
                          `/addassets/${this.props.match.params.projectId}`
                        );
                      }}
                    >
                      Add Static Banners
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        this.props.history.push(
                          `/addhtml5assets/${this.props.match.params.projectId}`
                        );
                      }}
                    >
                      Add HTML5 Banners
                    </MenuItem>
                  </Menu>
                </Box>
                <Box flexGrow={2}>
                  <TheBreadcrumb
                    partnerName={this.state.partnername}
                    projectName={this.state.projectname}
                    partnerId={this.state.partnerId}
                    assetName={"Assets"}
                    clientName={this.state.clientName}
                    clientId={this.state.clientId}
                  />
                </Box>
              </Fragment>
            ) : (
              <Box>
                <TheBreadcrumb
                  partnerName={this.state.partnername}
                  projectName={this.state.projectname}
                  partnerId={this.state.partnerId}
                  assetName={"Assets"}
                />
              </Box>
            )}
          </Box>

          <Box
            //width="1000px"
            display="flex"
            p={3}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {getPolicy() === "trailerparkuser" ? (
              <Box
                m={1}
                p={2}
                style={{
                  backgroundColor: "#333",
                  border: "1px solid rgba(255, 81, 0, 0.3)",
                }}
              >
                <Badge
                  badgeContent={this.state.comments.length}
                  color="secondary"
                  overlap="rectangle"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      this.setState({ showComments: "block" });
                      this.setState({ commentButton: false });
                    }}
                    disabled={!this.state.commentButton}
                  >
                    Show All Comments
                  </Button>
                </Badge>

                <Button
                  variant="contained"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    this.setState({ showComments: "none" });
                    this.setState({ commentButton: true });
                  }}
                  style={{ marginLeft: "15px" }}
                  disabled={this.state.commentButton}
                >
                  Hide All Comments
                </Button>

                <MaterialTable
                  style={{
                    backgroundColor: "#333",
                    minWidth: "600px",
                    display: this.state.showComments,
                  }}
                  title="All Assets Comments"
                  columns={[
                    {
                      title: "Last Modified",
                      field: "updatedAt",
                      type: "datetime",
                      defaultSort: "desc",
                    },
                    {
                      title: "Asset Name",
                      field: "asset.assetname",
                      render: (rowData) => (
                        <div style={{ width: "450px" }}>
                          {rowData.asset.assetname}
                        </div>
                      ),
                      cellStyle: {
                        width: "450px",
                        margin: "10px",
                        backgroundColor: "#555",
                      },
                    },
                    {
                      title: "Comment",
                      field: "assetcomment",
                      render: (rowData) => (
                        <div style={{ width: "400px" }}>
                          {rowData.assetcomment}
                        </div>
                      ),
                      cellStyle: {
                        width: "450px",
                        margin: "10px",
                        backgroundColor: "#333",
                      },
                    },
                    {
                      title: "User",
                      field: "user.username",
                    },
                  ]}
                  data={this.state.comments}
                  TableBody
                  options={{
                    tableLayout: "auto",
                    loadingType: "overlay",
                  }}
                />
              </Box>
            ) : null}

            <MaterialTable
              style={{
                backgroundColor: "#222",
                margin: "5px",
              }}
              columns={[
                {
                  title: "Rounds",
                  field: "rounds",
                  hidden: true,
                  cellStyle: {
                    width: 0,
                  },
                },
                {
                  title: "Approved Round",
                  field: "approvedround",
                  hidden: true,
                  cellStyle: {
                    width: 0,
                  },
                },
                {
                  title: "ASSET ID",
                  field: "id",
                  hidden: true,
                },
                {
                  title: "ASSET NAME",
                  field: "assetname",
                  filtering: false,
                  headerStyle: {
                    minWidth: "400px",
                  },
                  cellStyle: {
                    minWidth: "400px",
                  },
                  editComponent: (props) => (
                    <TextField
                      style={{
                        width: "400px",
                        wordWrap: "break-word",
                      }}
                      multiline
                      type="text"
                      placeholder="Asset Name"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                    />
                  ),
                },
                {
                  title: "Static Rounds",
                  field: `rounds.length`,
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                  },
                },
                {
                  title: "HTML5 Rounds",
                  field: `html_version_links.length`,
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                  },
                },
                {
                  title: "TYPE",
                  field: `type`,
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                  },
                },
                {
                  title: "WIDTH",
                  field: "bannerwidth",
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                  },
                },
                {
                  title: "HEIGHT",
                  field: "bannerheight",
                  editable: "never",
                  cellStyle: {
                    textAlign: "center",
                  },
                },
                {
                  title: "LAST MODIFIED",
                  field: "updatedAt",
                  defaultSort: "desc",
                  editable: "never",
                  type: "datetime",
                },
                {
                  title: "STATUS",
                  field: "status",
                  initialEditValue: "In Review",
                  cellStyle: (rowData) => ({
                    color: rowData === "In Review" ? "#ffff33" : "#33cc33",
                    textAlign: "center",
                  }),

                  lookup: {
                    inreview: "In Review",
                    approved: "Approved",
                  },
                },
                {
                  title: "PUBLISHED",
                  field: "publish",
                  initialEditValue: false,
                  type: "boolean",
                  cellStyle: {
                    color: "#FFF",
                  },
                },
              ]}
              data={
                this.state.userPolicy === "trailerparkuser"
                  ? this.state.assets
                  : this.state.assets.filter(
                      (project) => project.publish === true
                    )
              }
              title={
                <Box display="flex" flexDirection="row">
                  <Box pl={2}>
                    <Typography>{this.state.projectname} assets</Typography>
                  </Box>
                </Box>
              }
              editable={
                this.state.userPolicy === "trailerparkuser"
                  ? {
                      onRowUpdate: (newData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            this.handleAssetUpdate(
                              newData.id,
                              newData.assetname,
                              newData.status,
                              newData.publish
                            );
                            resolve();
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            this.handleDelete(oldData.id);
                            resolve();
                          }, 600);
                        }),
                    }
                  : {}
              }
              options={{
                paging: false,
                tableLayout: "auto",
                filtering: false,
                detailPanelColumnAlignment: "left",
                loadingType: "overlay",
              }}
              detailPanel={[
                {
                  //icon: () => <ArrowForwardIosIcon color="action" />,
                  tooltip: "View/Edit Rounds",
                  render: (rowData) => {
                    return (
                      <Fragment>
                        <Box
                          id={rowData.id}
                          style={{
                            width: "1400px",
                            backgroundColor: "#fff",
                          }}
                        >
                          {rowData.type === "static" ? (
                            <AssetTabs
                              projectId={this.props.match.params.projectId}
                              assetId={rowData.id}
                              assetName={rowData.assetname}
                              rounds={rowData.rounds}
                              assetStatus={rowData.status}
                              reMount={this.updateTheState}
                              approvedR={rowData.approvedround}
                              clientName={this.state.clientName}
                            />
                          ) : (
                            <Html5AssetTabs
                              projectId={this.props.match.params.projectId}
                              assetId={rowData.id}
                              assetName={rowData.assetname}
                              assetStatus={rowData.status}
                              reMount={this.updateTheState}
                              approvedR={rowData.approvedround}
                              html5Rounds={rowData.html_version_links}
                              bannerWidth={rowData.bannerwidth}
                              bannerHeight={rowData.bannerheight}
                              projectId={this.props.match.params.projectId}
                              clientName={this.state.clientName}
                            />
                          )}
                        </Box>
                        <Box m={2} display="flex" flexDirection="row">
                          <Comments assetId={rowData.id} assetComps={rowData.rounds} />

                          {rowData.type === "html5" ? null : (
                            <Box
                              ml={2}
                              pl={2}
                              pr={2}
                              pt={1}
                              pb={1}
                              style={{
                                backgroundColor: "#333",
                                color: "rgba(255, 81, 0, 1)",
                              }}
                            >
                              {`https://creativereview.trailerpark.com/shareassets/${rowData.id}`}
                            </Box>
                          )}
                        </Box>
                      </Fragment>
                    );
                  },
                },
              ]}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Assets;
