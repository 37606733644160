import React, { useState } from "react";
import { Box, Typography, Button, CardMedia } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, apiurl } from "../utils/utils";
import axios from "axios";
import UpLoadProgressBar from "../utils/UpLoadProgressBar";

// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1.5),
      width: "50ch",
    },
  },
  form: {
    backgroundColor: "#222",
  },
  media: {
    height: "200px",
  },
  label: {
    color: theme.palette.primary.main,
  },
  input: {
    display: "none",
  },
  table: {
    minWidth: 650,
    border: 1,
    borderBlockStyle: "solid",
  },
}));

const AddNewAsset = (props) => {
  const classes = useStyles();
  const token = getToken();
  const { projectId } = props.match.params;

  const [state, setState] = useState({
    assets: [],
    allowSubmit: true,
  });

  const [showProgressBar, setshowProgressBar] = useState(false);
  const [uploadPrecentage, setuploadPrecentage] = useState(0);

  // Assets Upload

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(state.assets);
    state.assets.map(async (asset) => {
      console.log(asset.name, asset, projectId, asset.width, asset.height);
      const data = {
        assetname: asset.name,
        rounds: asset,
        archive: false,
        publish: true,
        status: "inreview",
        type: "static",
        bannerwidth: asset.width,
        bannerheight: asset.height,
        project: projectId,
      };

      try {
        await axios.post(`${apiurl}/assets`, data, {
          headers: { Authorization: `Bearer ${token}` },
        });
        props.history.push(`/assets/${projectId}`);
      } catch (error) {
        console.error(error);
      }
    });

    // window.location = `/assets/${projectId}`;
  };

  // Assets Selection
  const handleAssetSelect = async (event) => {
    event.preventDefault();
    setshowProgressBar(true);
    const allFiles = event.target.files;

    const data = new FormData();

    for (let i = 0; i < allFiles.length; i++) {
      console.log(allFiles[i]);
      data.append("files", allFiles[i]);
    }

    const assets = await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setuploadPrecentage(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    });
    console.log(assets.data);
    setState({
      assets: assets.data,
      allowSubmit: false,
    });
  };
  return (
    <Box display="flex" borderBottom={0.1} minHeight="83vh">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRight={0.1}
        p={2}
        style={{ width: "250x", backgroundColor: "#222" }}
      >
        <Typography color="primary"> Uploading new Assets </Typography>
        {/* <SideBar partners={""} projects={""} partner={""} project={""} /> */}
      </Box>
      <Box
        width="100%"
        minHeight="80vh"
        display="flex"
        flexDirection="column"
        borderBottom={0.1}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "#222", width: "100%" }}
          pt={2}
          pb={2}
        >
          <Box>
            <Typography color="primary"> Uploading new Assets </Typography>
            {/* <TheBreadcrumb /> */}
          </Box>
        </Box>
        <Box
          style={{
            minWidth: "80vw",
          }}
          display="flex"
          p={3}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            style={{
              minWidth: "80vw",
              backgroundColor: "#222",
              padding: "10px",
              borderRadius: "15px",
            }}
          >
            <h2>Uploading assets to project</h2>
            <br />
            {/* <Typography className={classes.label}>Assets</Typography> */}
            <input
              accept="image/*"
              className={classes.input}
              id="logo-button"
              type="file"
              multiple
              onChange={handleAssetSelect}
            />
            <label htmlFor="logo-button">
              <Button variant="outlined" color="primary" component="span">
                Select new asset/s
              </Button>
            </label>
            <br />
            <br />
            {showProgressBar === true ? (
              <UpLoadProgressBar uploadPrecentage={uploadPrecentage} />
            ) : null}
            <br />
            <br />
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#222" }}>
                    <TableCell>ASSET NAME</TableCell>
                    <TableCell>TYPE</TableCell>
                    <TableCell>WIDTH</TableCell>
                    <TableCell>HEIGHT</TableCell>
                    <TableCell align="center">PREVIEW</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.assets.map((file) => (
                    <TableRow key={file.id}>
                      <TableCell component="th" scope="row">
                        {file.name}
                      </TableCell>
                      <TableCell>{file.mime}</TableCell>
                      <TableCell>{file.width}</TableCell>
                      <TableCell>{file.height}</TableCell>
                      <TableCell>
                        <CardMedia
                          className={classes.media}
                          style={{
                            width: file.formats.thumbnail.width,
                            height: file.formats.thumbnail.height,
                          }}
                          image={`${file.formats.thumbnail.url}`}
                          title="something"
                          width={file.formats.thumbnail.width}
                          height={file.formats.thumbnail.height}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <form
              className={classes.root}
              autoComplete="off"
              onSubmit={(event) => handleSubmit(event)}
            >
              <br />
              <br />
              <Box>
                <Button
                  disabled={state.allowSubmit}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {state.allowSubmit === true ? (
                    <Typography style={{ color: "#6f6f6f" }}>
                      Upload Assets
                    </Typography>
                  ) : (
                    <Typography>Upload Assets</Typography>
                  )}
                </Button>
                <Button
                  style={{ marginLeft: "15px" }}
                  disabled={!state.allowSubmit}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.history.push(`/assets/${projectId}`);
                  }}
                >
                  {state.allowSubmit !== true ? (
                    <Typography style={{ color: "#6f6f6f" }}>Close</Typography>
                  ) : (
                    <Typography>Close</Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewAsset;
