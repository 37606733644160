const TOKEN_KEY = "jwt";
const USER_ID_KEY = "id";
const POLICY_KEY = "policy";
const CLIENT_KEY = "client";

// Auth

export const getToken = (tokenkey = TOKEN_KEY) => {
  if (localStorage && localStorage.getItem(tokenkey)) {
    return JSON.parse(localStorage.getItem(tokenkey));
  }
  return null;
};

export const getClient = (clientkey = CLIENT_KEY) => {
  if (localStorage && localStorage.getItem(clientkey)) {
    return JSON.parse(localStorage.getItem(clientkey));
  }
  return null;
};

export const getUserId = (idkey = USER_ID_KEY) => {
  if (localStorage && localStorage.getItem(idkey)) {
    return JSON.parse(localStorage.getItem(idkey));
  }
  return null;
};
export const getPolicy = (policykey = POLICY_KEY) => {
  if (localStorage && localStorage.getItem(policykey)) {
    return JSON.parse(localStorage.getItem(policykey));
  }
  return null;
};

export const setToken = (value, tokenkey = TOKEN_KEY) => {
  if (localStorage) {
    localStorage.setItem(tokenkey, JSON.stringify(value));
  }
};

export const setClient = (value, clientkey = CLIENT_KEY) => {
  if (localStorage) {
    localStorage.setItem(clientkey, JSON.stringify(value));
  }
};

export const setUserId = (value, idkey = USER_ID_KEY) => {
  if (localStorage) {
    localStorage.setItem(idkey, JSON.stringify(value));
  }
};

export const setPolicy = (value, policykey = POLICY_KEY) => {
  if (localStorage) {
    localStorage.setItem(policykey, JSON.stringify(value));
  }
};

export const clearToken = (tokenkey = TOKEN_KEY) => {
  if (localStorage) {
    localStorage.removeItem(tokenkey);
  }
};

export const clearClient = (clientkey = CLIENT_KEY) => {
  if (localStorage) {
    localStorage.removeItem(clientkey);
  }
};

export const clearUserId = (idkey = USER_ID_KEY) => {
  if (localStorage) {
    localStorage.removeItem(idkey);
  }
};

export const clearPolicy = (policykey = POLICY_KEY) => {
  if (localStorage) {
    localStorage.removeItem(policykey);
  }
};

// export const apiurl = process.env.API_URL || "http://34.212.171.250:1337";
export const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
