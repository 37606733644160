import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getPolicy, getToken } from "../utils/utils";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Moment from "react-moment";
import axios from "axios";
const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

const useStyles = makeStyles({
  root: {
    width: 300,
    backgroundColor: "#222",
    border: "solid 1px #646464",
  },
  Link: {
    textDecoration: "none",
    color: "#fff",
  },
  BoxWrapper: {
    backgroundColor: "#222",
  },
});

const PartnerCard = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const data = {
      archive: true,
    };

    const token = getToken();
    await axios.put(`${apiurl}/partners/${props.partnerId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
    getPolicy() === "trailerparkuser"
      ? (window.location = `/partners/${props.clientId}`)
      : (window.location = "/partners");
    setOpen(false);
  };

  const policy = getPolicy();

  const classes = useStyles();
  return (
    <React.Fragment>
      <Card className={classes.root}>
        <CardActionArea
          onClick={() => (window.location = `/projects/${props.partnerId}`)}
        >
          <Box display="flex" direction="col" className={classes.BoxWrapper}>
            <Box
              style={{
                width: "125px",
                borderBottom: "solid 1px #646464",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <img
                  width="100px"
                  src={props.partnerLogo}
                  alt={props.partnerName}
                />
              </Box>
            </Box>

            <Box
              style={{
                width: "240px",
                borderLeft: "solid 1px #646464",
                borderBottom: "solid 1px #646464",
              }}
              p={2}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textSecondary"
              >
                {props.partnerName}
              </Typography>
              <Typography variant="body2" color="textPrimary" component="p">
                <strong>Date Created:</strong>
                <br />
                <Moment format="YYYY-MM-DD HH:mm">{props.creationData}</Moment>
              </Typography>
              <br />
              <Typography variant="body2" color="textPrimary" component="p">
                <strong>Last Modified:</strong> <br />
                <Moment format="YYYY-MM-DD HH:mm">{props.updateDate}</Moment>
              </Typography>
              <br />
              <Typography variant="body2" color="textPrimary" component="p">
                <strong>Number Projects: </strong>
                <strong>{props.numberOfProjects}</strong>
              </Typography>
            </Box>
          </Box>
        </CardActionArea>
        {policy === "trailerparkuser" ? (
          <CardActions>
            <Button
              onClick={() =>
                (window.location = `/editpartner/${props.partnerId}`)
              }
              size="medium"
            >
              <EditIcon color="primary" /> Edit
            </Button>
            <Button onClick={handleClickOpen} size="medium">
              <DeleteOutlineIcon color="primary" /> Delete
            </Button>
          </CardActions>
        ) : (
          <div></div>
        )}
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          style={{
            backgroundColor: "#222",
            border: "solid 1px #ffffff",
            radius: "10px",
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete"} {props.partnerName} {"?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete} color="primary">
              Yes
            </Button>
            <Button onClick={handleClose} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default PartnerCard;
