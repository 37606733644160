import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { Box, Typography } from "@material-ui/core";
import { getUserId, getToken, apiurl } from "../utils/utils";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Strapi from "strapi-sdk-javascript/build/main";
import { ArrowRightSharp } from "@material-ui/icons";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

export default class Comments extends Component {
  state = {
    loggedUser: getUserId(),
    token: getToken(),
    assetId: this.props.assetId,
    drawerStatus: false,
    assetTitle: "",
    comments: [],
    currentComment: "",
    rounds: this.props.assetComps,
    selectedRound: '',
  };



  async componentDidMount() {

    if (this.state.rounds.length === 1)
      this.setState({ selectedRound: `COMP - 1` });

    if (this.state.rounds.length > 1)
      this.setState({ selectedRound: `COMP - ${this.state.rounds.length}` })

    try {
      const assetComments = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            asset(id:"${this.state.assetId}"){
              assetname
              comments{
                id
                assetcomment
                createdAt
                user{
                  id
                  firstname
                  lastname
                  image{
                    url
                  }
                }
              }
            }
          }
          `,
        },
      });
      this.setState({
        assetTitle: assetComments.data.asset.assetname,
        comments: assetComments.data.asset.comments.slice(0).reverse(),
      });
    } catch (error) {
      console.error(error);
    }
  }

  addComment = async () => {
    const data = {
      asset: this.state.assetId,
      user: this.state.loggedUser,
      assetcomment: `${this.state.selectedRound} ::> ${this.state.currentComment}`,
    };
    try {
      await axios.post(`${apiurl}/comments`, data, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
    } catch (error) { }

    this.componentDidMount();
    this.setState({ currentComment: "" });
  };

  deleteComment = async (commentId) => {
    try {
      await axios.delete(`${apiurl}/comments/${commentId}`, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
    } catch (error) { }
    this.componentDidMount();
  };

  handleChange = (event) => {
    this.setState({ selectedRound: event.target.value });
  };


  render() {
    console.log("ROUNDS: ", this.state.rounds);

    return (
      <Box>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            this.setState({ drawerStatus: true });
          }}
        >
          Asset Comments
        </Button>
        <Drawer
          anchor="right"
          open={this.state.drawerStatus}
          onClose={() => {
            this.setState({ drawerStatus: false });
          }}
        >
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            style={{ backgroundColor: "#222", width: "500px" }}
          >
            <Box mt={1} mb={2} pb={2} borderBottom={2}>
              <Typography color="textPrimary">
                {this.state.assetTitle}
              </Typography>
            </Box>
            <TextField
              style={{
                backgroundColor: "#111",
              }}
              id="filled-multiline-static"
              label="ADD COMMENT"
              color="primary"
              multiline
              rows={5}
              value={this.state.currentComment}
              variant="outlined"
              onChange={(e) =>
                this.setState({ currentComment: `${e.target.value}` })
              }
            />
            <Box mt={2}>
              <InputLabel id="demo-simple-select-label">Selected Round</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.selectedRound}
                label="Age"
                onChange={this.handleChange}
                defaultValue={this.state.selectedRound}
              >
                {
                  this.state.rounds.map((round, index) => (
                    <MenuItem key={round.id} value={`COMP - ${index + 1}`}>
                      COMP - {index + 1}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box mt={2} />
            <Button
              color="primary"
              variant="outlined"
              onClick={this.addComment}
            >
              Add comment
            </Button>
          </Box>
          <Box
            p={2}
            style={{ backgroundColor: "#222", width: "500px", height: "100%" }}
          >
            <TableContainer component={Box}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell align="center">Comments</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.comments.map((comment) => (
                    <TableRow key={comment.id}>
                      <TableCell component="th" scope="row">
                        <Avatar
                          alt={comment.user.firstname}
                          src={comment.user.image.url}
                        />
                        {comment.user.firstname}
                      </TableCell>
                      <TableCell align="left">{comment.assetcomment}</TableCell>
                      <TableCell align="left">
                        {this.state.loggedUser === comment.user.id ? (
                          <Button
                            onClick={(e) => this.deleteComment(comment.id)}
                          >
                            <DeleteForeverIcon />
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Drawer>
      </Box>
    );
  }
}
