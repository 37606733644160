import React, { Component } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import SideBar from "../SideBar";
import Strapi from "strapi-sdk-javascript/build/main";
import TheBreadcrumb from "../utils/TheBreadcrumb";
import MaterialTable from "material-table";
import { getPolicy, getToken, apiurl } from "../utils/utils";
import axios from "axios";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";
const strapi = new Strapi(apiurl);

class Projects extends Component {
  state = {
    clientName: "",
    clientId: "",
    partnername: "",
    partnerlogo: "",
    projects: [],
    userPolicy: getPolicy(),
  };

  async componentDidMount() {
    try {
      const projectsData = await strapi.request("post", "graphql", {
        data: {
          query: `
          query{
            partner(id:"${this.props.match.params.partnerId}"){
              id
              partnername
              partnerlogo{
                url
              }
              clients{
                id
                clientname
              }
              projects(where: { archive: false }){
                id
                projectname
                projectdescription
                createdAt
                updatedAt
                projectstatus
                publish
              }
            }
          }
          `,
        },
      });
      this.setState({
        clientName: projectsData.data.partner.clients[0].clientname,
        clientId: projectsData.data.partner.clients[0].id,
        projects: projectsData.data.partner.projects,
        partnername: projectsData.data.partner.partnername,
        partnerlogo:
          projectsData.data.partner.partnerlogo === null
            ? "https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png"
            : projectsData.data.partner.partnerlogo.url,
      });
    } catch (error) {
      console.error(error);
    }
  }

  addNewProject = async (
    projectName,
    projectDescription,
    projectStatus,
    projectPublishStatus
  ) => {
    const data = {
      projectname: projectName,
      projectdescription: projectDescription,
      partner: this.props.match.params.partnerId,
      publish: projectPublishStatus,
      projectstatus: projectStatus,
    };

    const token = getToken();
    await axios.post(`${apiurl}/projects`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    this.componentDidMount();
  };

  handleProjectUpdate = async (
    projectId,
    projectName,
    projectDescription,
    projectStatus,
    projectPublishStatus
  ) => {
    const data = {
      projectname: projectName,
      projectdescription: projectDescription,
      publish: projectPublishStatus,
      projectstatus: projectStatus,
    };

    const token = getToken();
    await axios.put(`${apiurl}/projects/${projectId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    this.componentDidMount();
  };

  handleDelete = async (projectId) => {
    const data = {
      archive: true,
    };

    const token = getToken();
    await axios.put(`${apiurl}/projects/${projectId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    this.componentDidMount();
  };

  render() {
    return (
      <Box display="flex" borderBottom={0.1} minHeight="83vh">
        {/** Removing Side Bar
        <Box borderRight={0.1} style={{ width: "240px", overflow: "hidden" }}>
          <SideBar
            partners={""}
            project={""}
            partner={this.state.partnername}
            partnerlogo={this.state.partnerlogo}
            projects={this.state.projects}
            client={this.state.clientName}
            clientId={this.state.clientId}
          />
        </Box>*/}
        <Box width="100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            pt={2}
            pb={2}
          >
            <TheBreadcrumb
              partnerName={this.state.partnername}
              projectName={"projects"}
              assetName={"Assets"}
              clientName={this.state.clientName}
              clientId={this.state.clientId}
            />
          </Box>

          <Box
            width="100%"
            display="flex"
            p={3}
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {this.state.userPolicy === "trailerparkuser" ? (
              <MaterialTable
                style={{ backgroundColor: "#222" }}
                columns={[
                  {
                    title: "Project ID",
                    field: "id",
                    hidden: true,
                  },
                  {
                    title: "PROJECT",
                    field: "projectname",
                    filtering: false,
                    editComponent: (props) => (
                      <TextField
                        style={{
                          minWidth: "350",
                        }}
                        multiline
                        type="text"
                        placeholder="Project Name"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      />
                    ),
                    cellStyle: {
                      backgroundColor: "#141414",
                      color: "#FFF",
                      minWidth: "250px",
                    },
                  },
                  {
                    title: "DETAILS",
                    field: "projectdescription",
                    editComponent: (props) => (
                      <TextField
                        style={{
                          minWidth: "350",
                        }}
                        multiline
                        type="text"
                        placeholder="Details"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      />
                    ),
                    cellStyle: {
                      color: "#FFF",
                      minWidth: "300px",
                    },
                  },
                  {
                    title: "CREATED",
                    field: "createdAt",
                    editable: "never",
                    type: "datetime",
                  },
                  {
                    title: "MODIFIED",
                    field: "updatedAt",
                    editable: "never",
                    type: "datetime",
                  },
                  {
                    title: "STATUS",
                    field: "projectstatus",
                    initialEditValue: "newproject",
                    lookup: {
                      newproject: "New Project",
                      inprogress: "In Progress",
                      completed: "Completed",
                    },
                  },
                  {
                    title: "PUBLISHED",
                    field: "publish",
                    initialEditValue: false,
                    type: "boolean",
                    cellStyle: {
                      color: "#FFF",
                    },
                  },
                ]}
                data={this.state.projects}
                title={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={`${this.state.partnerlogo}`}
                      width="75px"
                      alt={this.state.partnername}
                    />
                    <Box pl={2}>
                      <Typography>
                        {this.state.clientName === "Peacock"
                          ? "Available partners"
                          : "Available projects"}{" "}
                        for {this.state.partnername}
                      </Typography>
                    </Box>
                  </Box>
                }
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        this.addNewProject(
                          newData.projectname,
                          newData.projectdescription,
                          newData.projectstatus,
                          newData.publish
                        );
                        resolve();
                      }, 600);
                    }),
                  onRowUpdate: (newData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        this.handleProjectUpdate(
                          newData.id,
                          newData.projectname,
                          newData.projectdescription,
                          newData.projectstatus,
                          newData.publish
                        );
                        resolve();
                      }, 600);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        this.handleDelete(oldData.id);
                        resolve();
                      }, 600);
                    }),
                }}
                onRowClick={(event, rowData, togglePanel) =>
                  (window.location = `/assets/${rowData.id}`)
                }
                options={{
                  draggable: true,
                  toolbar: true,
                  loadingType: "linear",
                  paging: false,
                  tableLayout: "auto",
                  filtering: false,
                  rowStyle: {
                    color: "#EEE",
                  },
                }}
                // actions={[
                //   (rowData) => ({
                //     icon: () => <ArtTrackTwoToneIcon fontSize="large" />,
                //     tooltip: "View/Update Assets",
                //     onClick: (event, rowData) => {
                //       console.log(rowData.id);
                //       window.location = `/assets/${rowData.id}`;
                //     },
                //   }),
                // ]}
              />
            ) : (
              <MaterialTable
                style={{ backgroundColor: "#222" }}
                columns={[
                  {
                    title: "Project ID",
                    field: "id",
                    hidden: true,
                  },
                  {
                    title: "PROJECT",
                    field: "projectname",
                    filtering: false,
                    cellStyle: {
                      backgroundColor: "#141414",
                      color: "#FFF",
                    },
                  },
                  {
                    title: "DETAILS",
                    field: "projectdescription",
                    editComponent: (props) => (
                      <TextField
                        multiline
                        type="text"
                        placeholder="Details"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                      />
                    ),
                    cellStyle: {
                      color: "#FFF",
                      minWidth: "350px",
                    },
                  },
                  {
                    title: "CREATED ON",
                    field: "createdAt",
                    editable: "never",
                    type: "datetime",
                  },
                  {
                    title: "LAST MODIFIED",
                    field: "updatedAt",
                    editable: "never",
                    type: "datetime",
                  },
                  {
                    title: "STATUS",
                    field: "projectstatus",
                    initialEditValue: "newproject",
                    lookup: {
                      newproject: "New Project",
                      inprogress: "In Progress",
                      completed: "Completed",
                    },
                  },
                  {
                    title: "PUBLISHED",
                    field: "publish",
                    initialEditValue: false,
                    type: "boolean",
                    hidden: true,
                  },
                ]}
                data={this.state.projects.filter(
                  (project) => project.publish === true
                )}
                title={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={`${this.state.partnerlogo}`}
                      width="75px"
                      alt={this.state.partnername}
                    />
                    <Box pl={2}>
                      <Typography>
                        Available projects for {this.state.partnername}
                      </Typography>
                    </Box>
                  </Box>
                }
                options={{
                  paging: false,
                  tableLayout: "fixed",
                  actionsColumnIndex: -1,
                  filtering: false,
                }}
                onRowClick={(event, rowData, togglePanel) =>
                  //(window.location = `/assets/${rowData.id}`)
                  this.props.history.push(`/assets/${rowData.id}`)
                }

                // actions={[
                //   (rowData) => ({
                //     icon: () => <ArtTrackTwoToneIcon fontSize="large" />,
                //     tooltip: "View/Update Assets",
                //     onClick: (event, rowData) => {
                //       console.log(rowData.id);
                //       window.location = `/assets/${rowData.id}`;
                //     },
                //   }),
                // ]}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default Projects;
