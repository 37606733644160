import React, { useState } from "react";
import Iframe from "react-iframe";
import { Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshIcon from "@material-ui/icons/Refresh";

export default function HtmlIframe(props) {
  const [iframURL, setIframURL] = useState(props.bannerURL);
  const [showSpinner, setShowSpinner] = useState(true);
  const hideSpinner = () => {
    setShowSpinner(false);
    setIframURL(props.bannerURL);
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        p="2"
        width={props.bannerWidth}
        height={props.bannerHeight}
      >
        {/* {showSpinner === false ? null : <CircularProgress color="primary"/>} */}
        <Iframe
          url={iframURL}
          width={props.bannerWidth}
          height={props.bannerHeight}
          display="initial"
          position="relative"
          scrolling="no"
          frameBorder="0"
          onLoad={hideSpinner}
          styles={{ backgroundcolor: "#000" }}
          style={{ zIndex: 1 }}
        />
      </Box>
      <Box display="flex" flexDirection="row">
      <Button
        variant="outlined"
        color="primary"
        component="span"
        onClick={() => {
          setShowSpinner(true);
          setIframURL(null);
        }}
      >
        <RefreshIcon fontSize="small" /> Reload Banner
      </Button>
      <Box style={{ height: "40px", backgroundcolor: "#333" }}>
        {showSpinner === false ? null : <CircularProgress color="primary" />}
      </Box>
      </Box>
    </>
  );
}
