import React from "react";
import { Toast } from "gestalt";
import { Box } from "@material-ui/core";

const ToastMessage = ({ show, message }) =>
  show && (
    <Box
      m={2}
      p={2}
      style={{
        backgroundColor: "#222",
        color: "red",
        borderRadius: "15px",
        transition: "2s",
      }}
    >
      <Toast text={message} />
    </Box>
  );

export default ToastMessage;
