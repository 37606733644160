import React, { useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Button, Typography } from "@material-ui/core";
import { getClient, getPolicy } from "./utils/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 360,
    backgroundColor: "#222",
    height: "100%",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const SideBar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          onClick={() =>
            getPolicy() === "trailerparkuser"
              ? (window.location = `/partners/${props.clientId}`)
              : (window.location = `/partners`)
          }
        >
          {getPolicy() === "trailerparkuser" ? (
            <Button fullWidth variant="outlined">
              <Typography color="primary">{props.client}</Typography>
            </Button>
          ) : (
            <Button fullWidth variant="outlined">
              <Typography color="primary">{getClient()}</Typography>
              {/* {getClient() === "HBOMax" ? (
                <Typography color="primary">HBOMax</Typography>
              ) : getClient() === "Peacock" ? (
                <Typography color="primary">Peacock</Typography>
              ) : getClient() === "Hulu" ? (
                <Typography color="primary">Hulu</Typography>
              ) : getClient() === "Gooding & Company" ? (
                <Typography color="primary">Gooding & Company</Typography>
              ) : getClient() === "Amazon" ? (
                <Typography color="primary">Amazon</Typography>
              ) : null} */}
            </Button>
          )}
        </ListSubheader>
      }
      className={classes.root}
    >
      {props.partner !== "" ? (
        <ListItem
          button
          divider
          onClick={() => (window.location = `/projects/${props.partnerId}`)}
        >
          {/* <ListItemIcon style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={props.partnerlogo} alt="" width="auto" height="20px" />
          </ListItemIcon> */}
          <ListItemText
            style={{ marginLeft: "15px" }}
            primary={props.partner}
          />
        </ListItem>
      ) : null}

      {props.project !== "" ? (
        <ListItem button divider>
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary={props.project} />
        </ListItem>
      ) : null}

      {props.partners !== "" ? (
        <Fragment>
          <ListItem button onClick={handleClick} divider>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            {getClient() === "Peacock" || props.client === "Peacock" ? (
              <ListItemText primary="Projects" />
            ) : (
              <ListItemText primary="Partners" />
            )}
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {props.partners.map((partner) => (
                <ListItem
                  button
                  className={classes.nested}
                  key={partner.id}
                  divider
                  onClick={() => (window.location = `/projects/${partner.id}`)}
                >
                  {/* <ListItemIcon
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {partner.partnerlogo === null ? (
                      <img
                        src="https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png"
                        alt=""
                        width="auto"
                        height="20px"
                      />
                    ) : (
                      <img
                        src={partner.partnerlogo.url}
                        alt=""
                        width="auto"
                        height="20px"
                      />
                    )}
                  </ListItemIcon> */}
                  <ListItemText
                    style={{ marginLeft: "15px" }}
                    primary={partner.partnername}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ) : null}
      {props.projects !== "" ? (
        <Fragment>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            {getClient() === "Peacock" || props.client === "Peacock" ? (
              <ListItemText primary="Partners" />
            ) : (
              <ListItemText primary="Projects" />
            )}
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {props.projects.map((project) => (
                <ListItem
                  button
                  className={classes.nested}
                  key={project.id}
                  onClick={() => (window.location = `/assets/${project.id}`)}
                >
                  <ListItemIcon>
                    <AccountTreeIcon />
                  </ListItemIcon>
                  <ListItemText primary={project.projectname} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ) : null}
    </List>
  );
};

export default SideBar;
