import React, { Component } from "react";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Switch,
  CardMedia,
} from "@material-ui/core";
import axios from "axios";
import { getToken, apiurl } from "./utils";
import UpLoadProgressBar from "./UpLoadProgressBar";
// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

export default class AddNewPartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnername: "",
      partnerlogo: `https://tpcrpuploadprovidertest.s3.us-west-1.amazonaws.com/templogo_e2569416e0.png`,
      publishStatus: false,
      token: getToken(),
      partnerLogoId: "5ef699c95c9d7e17fa94be1b",
      uploadPrecentage: 0,
      showProgressBar: false,
      clientId: this.props.match.params.clientId,
    };
  }

  handleLogoChange = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];

    const data = new FormData();

    data.append("files", file);

    const upload_logo = await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.state.token}`,
      },
      onUploadProgress: (progressEvent) => {
        this.setState({
          showProgressBar: true,
          uploadPrecentage: parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          ),
        });
      },
    });
    setTimeout(() => this.setState({ showProgressBar: false }), 1000);
    console.log(upload_logo.data);
    this.setState({
      partnerlogo: `${upload_logo.data[0].url}`,
      partnerLogoId: upload_logo.data[0].id,
    });
    console.log(this.state);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      partnername: this.state.partnername,
      partnerlogo: this.state.partnerLogoId,
      publish: this.state.publishStatus,
      clients: [{ _id: this.state.clientId }],
    };

    try {
      await axios.post(`${apiurl}/partners`, data, {
        headers: { Authorization: `Bearer ${this.state.token}` },
      });
    } catch (error) {}
    console.log(this.state.clientId);
    window.location = `/partners/${this.state.clientId}`;
  };

  handlePublishChange = (event) => {
    console.log(event.target.checked);

    this.setState({
      publishStatus: event.target.checked,
    });
  };

  handleNameChange = (event) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    return (
      <Box display="flex">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRight={0.1}
          style={{ width: "340px", backgroundColor: "#222", height: "85vh" }}
        >
          <Typography color="primary"> Adding new Partner </Typography>
          {/* <SideBar partners={""} projects={""} partner={""} project={""} /> */}
        </Box>

        <Box width="100%" height="85vh" display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#222" }}
            pt={2}
            pb={2}
          >
            <Box>
              <Typography color="primary"> Adding new Partner </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            m={3}
            p={2}
            flexDirection="column"
            style={{
              backgroundColor: "#222",
              width: "80ch",
              borderRadius: "10px",
            }}
          >
            <Box>
              <h2>Adding New Partner </h2>
            </Box>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <TextField
                id="partner-name"
                label={"Add Partner Name"}
                value={this.state.partnername}
                name="partnername"
                onChange={this.handleNameChange}
                fullWidth
              />
              <br />
              <br />
              {this.state.showProgressBar === true ? (
                <UpLoadProgressBar
                  uploadPrecentage={this.state.uploadPrecentage}
                />
              ) : null}
              <br />
              <br />
              <Typography>Upload Partner Logo</Typography>
              <br />
              <CardMedia
                image={this.state.partnerlogo}
                title={this.state.partnername}
                width="400"
                height="400"
                style={{ height: "300px" }}
              />
              <Box pt={3}>
                <input
                  accept="image/*"
                  id="logo-button"
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.handleLogoChange}
                />
                <label htmlFor="logo-button">
                  <Button variant="outlined" color="primary" component="span">
                    Upload
                  </Button>
                </label>
              </Box>
              <Box pt={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.publishStatus}
                      onChange={this.handlePublishChange}
                      name="publishStatus"
                      color="primary"
                    />
                  }
                  label="Publish"
                />
              </Box>
              <Box pt={2} display="flex" flexDirection="row">
                <Box>
                  <Button variant="contained" color="primary" type="submit">
                    Add
                  </Button>
                </Box>
                <Box pl={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      (window.location = `/partners/${this.state.clientId}`)
                    }
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    );
  }
}
