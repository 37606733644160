import React from "react";
import Logo from "./assets/logov3.png";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  Link: {
    textDecoration: "none",
  },

  tpheader: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignContent: "flex-end",
    padding: "10px",
    textTransform: "uppercase",
  },
  logoText: {
    letterSpacing: "3.2px",
    fontSize: "20px",
    textTransform: "uppercase",
  },
}));

const TopBar = () => {
  const classes = useStyle();

  return (
    <Box
      display="flex"
      flexDirection="row"
      bgcolor="background.paper"
      justifyContent="flex-end"
      borderBottom={0.1}
      pl={1}
    >
      <Box
        className={classes.tpheader}
        borderColor="text.primary"
        display="flex"
        flexGrow={1}
      >
        <img src={Logo} width="270px" alt="" />
      </Box>
      <Box flexGrow={2} pt={3}>
        <div className={classes.logoText}>Creative Review</div>
      </Box>
    </Box>
  );
};

export default TopBar;
